<template>
  <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header border-0">
      <!--begin::Card title-->
      <div class="card-title m-0 d-flex justify-content-between w-100">
        <!--begin::Navs-->
        <div class="h3">
          Daftar {{ title }}
          <i
            @click="resetData"
            class="ms-1 text-primary fs-3 bi bi-arrow-clockwise cursor-pointer"
          ></i>
        </div>

        <button
          class="btn btn-primary btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#modal-create"
        >
          Tambah {{ title }}
        </button>
        <!--begin::Navs-->
      </div>
      <!--end::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Card body-->
    <div class="card-body border-top p-9">
      <div class="table-responsive mt-md-n5">
        <table
          class="table table-bordered table-row-bordered table-hover"
          v-if="data"
        >
          <thead>
            <tr class="fw-bolder fs-5 border-bottom">
              <th>Nama</th>
              <th>Username</th>
              <th>Email</th>
              <th>Telepon</th>
              <th>Kategori</th>
              <th>Gambar</th>
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, i) in data"
              :key="i"
              @click="getDetail(item)"
              class="cursor-pointer"
            >
              <td>{{ item.user.name }}</td>
              <td>{{ item.user.username }}</td>
              <td>{{ item.user.email }}</td>
              <td>{{ item.user.phone_number }}</td>
              <td>{{ item.role.name }}</td>
              <td>{{ item.user.image }}</td>
              <td>
                <button
                  @click="destroyData(item)"
                  class="btn btn-sm btn-danger"
                >
                  <i class="bi bi-trash"></i> Hapus Data
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="text-center fw-bolder fs-4">Belum ada data</div>
      </div>
      <div class="row" v-if="data">
        <div
          class="
            col-sm-12 col-md-5
            d-flex
            align-items-center
            justify-content-center justify-content-md-start
          "
        >
          <div class="dataTables_length" id="kt_customers_table_length">
            <label>
              <select
                name="kt_customers_table_length"
                class="form-select form-select-sm form-select-solid"
                @change="changeLimit"
              >
                <option :selected="limit == 10" value="10">10</option>
                <option :selected="limit == 25" value="25">25</option>
                <option :selected="limit == 50" value="50">50</option>
                <option :selected="limit == 100" value="100">100</option>
              </select>
            </label>
          </div>
        </div>
        <div
          class="
            col-sm-12 col-md-7
            d-flex
            align-items-center
            justify-content-center justify-content-md-end
          "
        >
          <paginate
            v-if="data"
            :total-data="totalPage"
            @set-rows="getRows"
            :current-row="page"
          />
        </div>
      </div>
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Basic info-->

  <!-- open modal detail -->
  <button
    id="open-modal-detail"
    class="rounded btn btn-sm btn-primary d-none"
    data-bs-toggle="modal"
    data-bs-target="#modal-detail"
  >
    Detail Data
  </button>

  <!-- Modal Detail -->
  <div
    class="modal fade"
    id="modal-detail"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Detail {{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <Form
          id="form-edit"
          class="form"
          novalidate="novalidate"
          @submit="FormUpdate"
          :validation-schema="formEdit"
        >
          <div class="modal-body">
            <div class="row">
              <input type="hidden" readonly name="id" v-model="detail.id" />
              <div class="col-lg-4 mb-5">
                <div class="col-lg-12">
                  <label for="">Profile {{ title }}</label
                  ><br />
                  <img
                    :src="detail.newFoto ? detail.newFoto : foto"
                    @click="changeFoto"
                    id="update-preview"
                    class="img-fluid cursor-pointer rounded-3"
                    style="max-height: 200px"
                    alt="foto"
                  />
                  <Field
                    type="file"
                    name="image"
                    id="update-foto"
                    accept="image/*"
                    @input="preFoto"
                    class="form-control d-none"
                  />
                  <Field
                    type="hidden"
                    readonly=""
                    name="image_val"
                    v-model="detail.data.gambar"
                    class="form-control"
                  />
                </div>
                <ErrorMessage
                  name="image"
                  class="fv-plugins-message-container invalid-feedback"
                ></ErrorMessage>
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-12 mb-5">
                    <label for="">Nama {{ title }}</label>
                    <Field
                      type="text"
                      name="name"
                      v-model="detail.name"
                      class="form-control form-control"
                    />
                    <ErrorMessage
                      name="name"
                      class="fv-plugins-message-container invalid-feedback"
                    ></ErrorMessage>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label for="">Username {{ title }}</label>
                    <Field
                      type="text"
                      name="username"
                      v-model="detail.username"
                      class="form-control form-control"
                    />
                    <ErrorMessage
                      name="username"
                      class="fv-plugins-message-container invalid-feedback"
                    ></ErrorMessage>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label for="">Email {{ title }}</label>
                    <Field
                      type="email"
                      name="email"
                      v-model="detail.email"
                      class="form-control form-control"
                    />
                    <ErrorMessage
                      name="email"
                      class="fv-plugins-message-container invalid-feedback"
                    ></ErrorMessage>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label for=""
                      >Password {{ title }}
                      <sup>Isi untuk melakukan perubahan Password</sup>
                    </label>
                    <Field
                      type="password"
                      name="password"
                      v-model="detail.password"
                      class="form-control form-control"
                    />
                    <ErrorMessage
                      name="password"
                      class="fv-plugins-message-container invalid-feedback"
                    ></ErrorMessage>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label for="">Telepon {{ title }}</label>
                    <Field
                      type="number"
                      name="phone_number"
                      v-model="detail.phone_number"
                      class="form-control form-control"
                    />
                    <ErrorMessage
                      name="phone_number"
                      class="fv-plugins-message-container invalid-feedback"
                    ></ErrorMessage>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              form="form-input"
              ref="editButton"
              class="btn btn-primary"
            >
              Simpan
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>

  <!-- Modal Tambah -->
  <div
    class="modal fade"
    id="modal-create"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Tambah {{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <Form
          id="form-input"
          class="form"
          novalidate="novalidate"
          @submit="FormSubmit"
          :validation-schema="form"
        >
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-4 mb-5">
                <div class="col-lg-12">
                  <label for="">Profile {{ title }}</label
                  ><br />
                  <img
                    :src="created.newFoto ? created.newFoto : foto"
                    @click="changeFoto"
                    id="update-preview"
                    class="img-fluid cursor-pointer rounded-3"
                    style="max-height: 200px"
                    alt="foto"
                  />
                  <Field
                    type="file"
                    name="image"
                    id="update-foto"
                    accept="image/*"
                    @input="preFoto"
                    class="form-control d-none"
                  />
                  <Field
                    type="hidden"
                    readonly=""
                    name="image_val"
                    class="form-control"
                  />
                </div>
                <ErrorMessage
                  name="image"
                  class="fv-plugins-message-container invalid-feedback"
                ></ErrorMessage>
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-12 mb-5">
                    <label for="">Nama {{ title }}</label>
                    <Field
                      type="text"
                      name="name"
                      v-model="detail.name"
                      class="form-control form-control"
                    />
                    <ErrorMessage
                      name="name"
                      class="fv-plugins-message-container invalid-feedback"
                    ></ErrorMessage>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label for="">Username {{ title }}</label>
                    <Field
                      type="text"
                      name="username"
                      v-model="detail.username"
                      class="form-control form-control"
                    />
                    <ErrorMessage
                      name="username"
                      class="fv-plugins-message-container invalid-feedback"
                    ></ErrorMessage>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label for="">Email {{ title }}</label>
                    <Field
                      type="email"
                      name="email"
                      v-model="detail.email"
                      class="form-control form-control"
                    />
                    <ErrorMessage
                      name="email"
                      class="fv-plugins-message-container invalid-feedback"
                    ></ErrorMessage>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label for=""
                      >Password {{ title }}
                      <sup>Isi untuk melakukan perubahan Password</sup>
                    </label>
                    <Field
                      type="password"
                      name="password"
                      v-model="detail.password"
                      class="form-control form-control"
                    />
                    <ErrorMessage
                      name="password"
                      class="fv-plugins-message-container invalid-feedback"
                    ></ErrorMessage>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label for="">Telepon {{ title }}</label>
                    <Field
                      type="number"
                      name="phone_number"
                      v-model="detail.phone_number"
                      class="form-control form-control"
                    />
                    <ErrorMessage
                      name="phone_number"
                      class="fv-plugins-message-container invalid-feedback"
                    ></ErrorMessage>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              form="form-input"
              ref="inputButton"
              class="btn btn-primary"
            >
              Simpan
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Paginate from "@/components/Paginate.vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  components: {
    ErrorMessage,
    Field,
    Form,
    Paginate,
  },
  setup() {
    const title = "Pengguna";
    const name = "Users";
    setCurrentPageTitle(title);
    const store = useStore();
    const path = window.location.origin + "/";
    const page = computed(() => store.state.UsersModule.page);
    const limit = computed(() => store.state.UsersModule.limit);
    const totalPage = computed(() => store.state.PartiesModule.totalPage);
    const baseUrl = ApiService.vueInstance.axios.defaults.baseURL;

    // call data required
    store.dispatch("All" + name);

    const data = computed(() => store.state.UsersModule.all);

    const foto = ref(path + "other/default/blank.png");
    const created = ref({
      newFoto: "",
      profile: "",
    });
    const detail = ref({
      newFoto: "",
      profile: "",
      data: {},
    });

    // store.commit("SET_ACTIVE_MENU", "admin.politic.kader.category");

    const inputButton = ref<HTMLElement | null>(null);
    const editButton = ref<HTMLElement | null>(null);

    const form = Yup.object().shape({
      name: Yup.string().required().label("Nama"),
      username: Yup.string().required().label("Username"),
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().required().label("Password"),
      phone_number: Yup.string().required().label("Telepon"),
      image_val: Yup.string().required().label("Gambar"),
    });

    const formEdit = Yup.object().shape({
      name: Yup.string().required().label("Nama"),
      username: Yup.string().required().label("Username"),
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().required().label("Password"),
      phone_number: Yup.string().required().label("Telepon"),
      image_val: Yup.string().required().label("Gambar"),
    });

    const getDetail = (item) => {
      // method 1
      // store.dispatch("detail" + name.toUpperCase(), item.id);
      // method 2
      detail.value.newFoto = baseUrl + item.user.logo;
      detail.value.data = item.user;

      setTimeout(() => {
        (document.getElementById("open-modal-detail") as HTMLElement).click();
      }, 300);
    };

    //Form submit function
    const FormSubmit = () => {
      if (inputButton.value) {
        // Activate indicator
        inputButton.value.setAttribute("data-kt-indicator", "on");
      }

      const val = document.getElementById("form-input") as HTMLFormElement;
      const data = new FormData(val);

      // Dummy delay
      setTimeout(() => {
        // Send request
        store
          .dispatch("store" + name, data)
          .then(() => {
            Swal.fire({
              text: store.state.UsersModule.msg,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(() => {
              // Go to page after successfully
              // goBack();
            });
          })
          .catch(() => {
            Swal.fire({
              text: store.state.UsersModule.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        //Deactivate indicator
        inputButton.value?.removeAttribute("data-kt-indicator");
      }, 1000);
    };
    const FormUpdate = () => {
      if (editButton.value) {
        // Activate indicator
        editButton.value.setAttribute("data-kt-indicator", "on");
      }

      const val = document.getElementById("form-edit") as HTMLFormElement;
      const data = new FormData(val);
      // Dummy delay
      setTimeout(() => {
        // Send request
        store
          .dispatch("store" + name, data)
          .then(() => {
            Swal.fire({
              text: store.state.UsersModule.msg,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(() => {
              // Go to page after successfully
              // goBack();
            });
          })
          .catch(() => {
            Swal.fire({
              text: store.state.UsersModule.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        //Deactivate indicator
        editButton.value?.removeAttribute("data-kt-indicator");
      }, 1000);
    };
    const changeLimit = (el) => {
      store.commit("SET_LIMIT_" + name.toUpperCase(), el.target.value);
      store.dispatch("All" + name);
    };
    const getRows = (val) => {
      store.dispatch("All" + name).then(() => {
        store.commit("SET_PAGE_" + name.toUpperCase(), val);
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
    };
    const destroyData = (item) => {
      Swal.fire({
        html: `<b>${title} ${item.user.name}</b>, Yakin dihapus?`,
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Hapus!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
        showLoaderOnConfirm: true,
        backdrop: true,
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          store
            .dispatch("destroy" + name, item.id)
            .then(() => {
              Swal.fire("Terhapus!", `${title} berhasil dihapus.`, "success");
            })
            .catch(() => {
              Swal.fire(store.state.UsersModule.error, "success");
            });
        }
      });
    };
    const resetData = () => {
      changeLimit({ target: { value: 10 } });
      getRows(1);
      store.dispatch("All" + name);
    };

    return {
      title,
      page,
      limit,
      totalPage,
      data,
      foto,
      created,
      detail,
      path,
      form,
      formEdit,
      getDetail,
      FormSubmit,
      FormUpdate,
      changeLimit,
      getRows,
      destroyData,
      resetData,
    };
  },
});
</script>
